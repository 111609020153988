import React,{useState,useEffect} from 'react';
import { useSelector } from "react-redux";

const Success = () => {
    const transaction = useSelector((state) => state.transaction);
    const [timeLeft, setTimeLeft] = useState(8); // Set initial time in seconds (e.g., 600 seconds = 10 minutes)

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(()=>{
        if(!transaction.gateway){
            return;
        }
        setTimeout(()=>{
            if(transaction.transaction.redirect_url === "-"){
                window.location.href = `${transaction.gateway.redirect_url}?tr=${transaction.transaction.id}`
            }else{
                window.location.href = `${transaction.transaction.redirect_url}?tr=${transaction.transaction.id}` 
            }
        },[8000])
    })

    return (
        <>
            <section className="mb-4 bank-wrapper">
                <div className="screen-wrapper">
                    {/* <div className="logo-wrapper">
                        <img src="/images/logo.png" alt="logo" />
                    </div> */}
                </div>

                <div className="screen-wrapper something-wrong-wrapper">
                </div>
                <div className="important-note-wrapper something-wrong-box-main">
                    <div className="something-wrong-box text-center">
                        <img src="/images/successfull-icon.png" alt="successfull-icon" />
                        <h5 className="success-text">SUCCESS</h5>
                        <p className="wrong-dec">Your transaction was successful! Thank you for using our service.</p>
                        <span className="order-id">
                            Transaction ID
                        </span>
                        <h5 className="transection-id">
                            {transaction.transaction.id}
                        </h5>
                        {transaction.gateway && <p>redirect within a {timeLeft} seconds</p>}
                    </div>
                </div>


            </section>
        </>
    )
}

export default Success